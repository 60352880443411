<script setup lang="ts">
const { email, clearAuthState } = useUserState();
const { $sentry } = useNuxtApp();

const onLogout = async () => {
  try {
    await logout();
  } catch (error) {
    $sentry.captureException(error);
    console.error(error);
    clearAuthState();
    await navigateTo('/login');
  }
};
</script>

<template>
  <div
    class="bg-onboarding-background relative z-0 flex h-dvh flex-col items-center justify-center overflow-hidden bg-cover bg-center bg-no-repeat p-6 sm:h-full sm:min-h-screen md:overflow-visible"
  >
    <div
      class="relative z-20 flex h-dvh w-full max-w-[640px] flex-col justify-between overflow-auto rounded-[40px] border-2 border-b-4 border-black bg-white pt-6 sm:p-10 md:h-full md:min-h-[640px] md:rounded-[20px] md:border md:border-b-4"
    >
      <div class="flex w-full flex-col items-center px-4">
        <slot name="header" />
        <slot name="content" />
      </div>
      <div
        v-if="$slots['footer-sticky']"
        class="sticky bottom-0 left-0 flex flex-col items-center bg-white pb-2 md:static"
      >
        <slot name="footer-sticky" />
      </div>

      <div
        v-if="$slots['footer']"
        class="flex flex-col items-center bg-white pb-6 md:static"
      >
        <slot name="footer" />
      </div>
    </div>
    <div
      v-if="email"
      class="mt-4 space-y-2 text-center text-sm text-black text-opacity-80"
    >
      <p>
        Logged in as: <strong>{{ email }}</strong>
      </p>
      <button class="underline" @click="onLogout">Logout</button>
    </div>
  </div>
</template>
